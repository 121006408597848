<template>
  <div class="container pt-4">
    <div class="row">
      <div class="col-md-12">
        <iframe
          src="https://calendar.google.com/calendar/embed?src=7fc5sf4a36t03ea6ghiltq0shc%40group.calendar.google.com&amp;ctz=America%2FNew_York"
          style="border: 0px none; width: 1170px; height: 877.5px"
          scrolling="no"
          data-origwidth="800"
          data-origheight="600"
          width="800"
          height="600"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalendarView",
};
</script>