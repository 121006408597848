<template>
  <div class="card-body">
    <div class="pb-4">This content is password-protected. Please enter the password below.</div>
    <div v-if="error" class="alert alert-danger">{{ error }}</div>
    <form action="#" @submit.prevent="Login">
      <div class="form-group row">
        <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>

        <div class="col-md-4">
          <input id="password" type="password" class="form-control" name="password" required v-model="password" />
          <button type="submit" class="btn btn-primary mt-2">Login</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "SharedLoginComponent",

  setup() {
    const password = ref("");
    const error = ref(null);
    const store = useStore();

    const Login = async () => {
      try {
        await store.dispatch("logIn", {
          email: "sharedwebsiteuser@whiteoak-church.org",
          password: password.value,
        });
      } catch (err) {
        error.value = err.message;
      }
    };
    return { Login, password, error };
  },
};
</script>