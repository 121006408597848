<template>
  <navbar></navbar>
  <main>
    <router-view></router-view>
  </main>
</template>

<script>
import navbar from "./components/Navbar";
export default {
  components: {
    navbar,
  },
  created() {
    document.querySelector("#static_about").style.display = "none";
  },
};
</script>
