<template>
  <div class="container pt-4">
    <div class="row">
      <div class="col-md-4">
        <h2>Contact</h2>
        <p>
          1211 N Penryn Rd<br />
          Manheim, PA 17545<br />
          (717) 665-5216
        </p>
      </div>
      <div class="col-md-4">
        <h2>Service Times</h2>
        <p>
          Sunday School 8:45am<br />
          Worship Service 9:45am<br />
        </p>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  name: "ContactView",
};
</script>