<template>
    <div class="container pt-4">
        <h3>Pictorial Directory Sign Ups</h3>
        <div class="row">
            <div class="col-md-12">
                <p>
                    Sign up for your pictorial directory photoshoot by using <a
                        href="https://mtphotographics.17hats.com/p#/scheduling/xdcnbskrvrvnxnxwdxzrbxwpzxvvkpwt"
                        target="_blank">this link</a> or by coming to the fellowship hall on Sunday, August 13, 20, or 27
                    after morning worship. Pictures for the pictorial directory will be taken September 5, 7, 8, 9, 11, and
                    12 in the fellowship hall.
                </p>
                <p>
                    The pictorial directory committee is in need of individuals or couples to serve as host/hostess during
                    the photoshoots. This would involve welcoming people and keeping the schedule flowing. If you are
                    interested in being a
                    host/hostess sign up at <a href="https://www.signupgenius.com/go/5080449ADAA28A6FB6-pictorial"
                        target="_blank">this link</a>.
                </p>
                <p>If you have any questions regarding photoshoot sign-ups or being a host/hostess, contact Brieanna
                    Heisey.</p>
            </div>
        </div>
    </div>
    <hr />
</template>