<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">Login</div>
          <div class="card-body">
            <div v-if="error" class="alert alert-danger">{{ error }}</div>
            <form action="#" @submit.prevent="Login">
              <div class="form-group row" v-if="showemail">
                <label for="email" class="col-md-4 col-form-label text-md-right">Email</label>

                <div class="col-md-6">
                  <input id="email" type="email" class="form-control" name="email" value required autofocus v-model="email" />
                </div>
              </div>

              <div class="form-group row">
                <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>

                <div class="col-md-6">
                  <input id="password" type="password" class="form-control" name="password" required v-model="password" />
                </div>
              </div>

              <div class="form-group row mb-0">
                <div class="col-md-8 offset-md-4">
                  <button type="submit" class="btn btn-primary">Login</button>
                </div>
              </div>
              <button @click="showemail=!showemail"></button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

const email = ref("sharedwebsiteuser@whiteoak-church.org");
const password = ref("");
const showemail = ref(false);
const error = ref(null);

const store = useStore();
const router = useRouter();
const route = useRoute();

const Login = async () => {
  try {
    await store.dispatch("logIn", {
      email: email.value,
      password: password.value,
    });
    router.push(route.query.redirect || '/')
  } catch (err) {
    error.value = err.message;
  }
};
</script>

<script>
export default { name: "LoginComponent" };
</script>
