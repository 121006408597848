import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import Dashboard from '../views/Dashboard.vue';
import Sermons from '../views/Sermons.vue';
import SermonsNew from '../views/SermonsNew.vue';
import Contact from '../views/Contact.vue';
import Beliefs from '../views/Beliefs.vue';
import MemberResources from '../views/MemberResources.vue';
// import ManheimProject from '../views/ManheimProject.vue';
// import VbsRegistration from '../components/VbsRegistration.vue';
import Calendar from '../views/Calendar.vue';
import PublicCalendar from '../views/PublicCalendar.vue';
import { auth } from "../firebaseConfig";

const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    });
};

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/statement-of-beliefs',
        name: 'Beliefs',
        component: Beliefs
    },
    {
        path: '/sermons/:after?',
        name: 'Sermons',
        component: Sermons,
        meta: { requiresAuth: true },
        props: true
    },
    {
        path: '/services',
        name: 'Services',
        meta: { requiresAuth: true },
        component: Sermons
    },
    {
        path: '/sermon-streaming',
        name: 'SermonsNew',
        component: SermonsNew
    },
    {
        path: '/member-resources',
        name: 'MemberResources',
        meta: { requiresAuth: true },
        component: MemberResources
    },
    {
        path: '/child-protection-training',
        name: 'ChildProtectionTraining',
        meta: { requiresAuth: true },
        component: MemberResources
    },
    {
        path: '/calendar',
        name: 'Calendar',
        component: PublicCalendar
    },
    {
        path: '/fhcalendar',
        name: 'FellowshipHallCalendar',
        component: Calendar
    },
    // {
    //     path: '/vbs',
    //     name: 'VbsRegistration',
    //     component: VbsRegistration
    // },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard
    },
    // {
    //     path: '/mp',
    //     name: 'ManheimProject',
    //     component: ManheimProject
    // }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
})

router.beforeEach(async (to) => {
    if (to.meta.requiresAuth) {
        const user = await getCurrentUser();
        if (!user) {
            return {
                path: '/login',
                query: {
                    redirect: to.fullPath,
                },
            }
        }
    }
})

export default router