import { createApp } from 'vue'
import App from './App.vue'
import router from './routes/index'
import store from './store'
import SharedLogin from './components/SharedLogin.vue'
import VbsRegistration from './components/VbsRegistration.vue';
import PhotoSignup from './components/PhotoSignup.vue';
import { app as firebaseApp } from './firebaseConfig'
import { VueFire } from 'vuefire'

const vueApp = createApp(App)
vueApp.component("SharedLogin", SharedLogin)
vueApp.component("VbsRegistration", VbsRegistration)
vueApp.component("PhotoSignup", PhotoSignup)

vueApp.use(router)
    .use(store)
    .use(VueFire, {
        firebaseApp,
        modules: [
            //VueFireAuth(),
        ],
    }).mount('#app')