<template>
  <div class="container pt-4">
    <h3>White Oak Church VBS Registration</h3>
    <h4>July 8-12, 2024</h4>
    <div class="row">
      <div class="col-md-12">
        <p>
          Pre-register your child/children by Saturday, July 6 by completing this form: <a
            :href="signupUrl"
            target="_blank">2024 VBS Registration Link</a>. Complete a digital form for each child that plans to attend
          White Oak's Vacation Bible School. Children who are not registered are still welcome; however they will need to
          register at the registration tables. On Monday, July 8 all children pre-registered or not, should check in at
          the registration tables located outside the main entrance to receive his or her assigned class. Registration
          opens at 6:30 and Bible School starts at 7:00.
        </p>
        <p>
          Students will meet their teacher and class on their assigned bench in the sanctuary prior to the start of Bible
          School. At the conclusion of Bible School (roughly 8:25), parents should promptly pick up children in Nursery
          through 3rd grade at their assigned benches. Students in Grades 4-8 will be dismissed to the narthex and can
          meet their parents at the conclusion of the service.
        </p>
        <a class="btn btn-secondary"
          :href="signupUrl"
          target="_blank">Register</a>
      </div>
    </div>
  </div>
  <hr />
</template>

<script setup>

const signupUrl = "https://docs.google.com/forms/d/e/1FAIpQLScmFiG-xoDBecyTf3lxwGjwI3pZEwDK3C96ibs2LSaHfSuQ1Q/viewform?usp=sf_link"

</script>