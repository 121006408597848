<template>
  <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsDefault"
      aria-controls="navbarsDefault" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarsDefault">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item mr-2">
          <router-link to="/" class="nav-link" data-toggle="collapse"
            data-target=".navbar-collapse.show">Home</router-link>
        </li>
        <li class="nav-item mr-2">
          <router-link to="/contact" class="nav-link" data-toggle="collapse"
            data-target=".navbar-collapse.show">Contact</router-link>
        </li>
        <li class="nav-item mr-2">
          <router-link to="/sermon-streaming" class="nav-link" data-toggle="collapse"
            data-target=".navbar-collapse.show">Sermon Streaming</router-link>
        </li>
        <li class="nav-item mr-2">
          <router-link to="/statement-of-beliefs" class="nav-link" data-toggle="collapse"
            data-target=".navbar-collapse.show">Beliefs</router-link>
        </li>
        <li class="nav-item mr-2">
          <router-link to="/calendar" class="nav-link" data-toggle="collapse"
            data-target=".navbar-collapse.show">Calendar</router-link>
        </li>
        <li class="nav-item mr-2">
          <router-link to="/member-resources" class="nav-link" data-toggle="collapse"
            data-target=".navbar-collapse.show">Member Resources</router-link>
        </li>
        <!-- <li class="nav-item mr-2">
          <router-link to="/vbs" class="nav-link" data-toggle="collapse"
            data-target=".navbar-collapse.show">VBS</router-link>
        </li> -->
        <li class="nav-item">
          <a href="https://tithe.ly/give_new/www/#/tithely/give-one-time/7222167" class="nav-link"
            data-target=".navbar-collapse.show">Give</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  setup () {
    const appName = process.env.VUE_APP_NAME;
    return { appName };
  },
};
</script>