<template>
  <div class="container pt-4">
    <div class="row">
      <div class="col-md-12">
        <iframe
          src="https://calendar.google.com/calendar/embed?src=2e908ead1199f94b1c6305f0f5cf2248deab6a3912fa3b797fa8ef27154079b8%40group.calendar.google.com&ctz=America%2FNew_York"
          style="border: 0px none; width: 1170px; height: 877.5px"
          scrolling="no"
          data-origwidth="800"
          data-origheight="600"
          width="800"
          height="600"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PublicCalendarView",
};
</script>