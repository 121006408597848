<template>
  <div class="container pt-4">
    <div class="pb-6">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr class="sermon-table-headings">
              <th scope="col">Date</th>
              <th scope="col">
                Speaker<br>
                <input type="text" size="12" v-model="search">
              </th>
              <th scope="col">Listen</th>
              <th scope="col">Title</th>
              <th scope="col">Passage</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sermon in sermonList" v-bind:key="sermon.id">
              <td>{{ formatSermonDate(sermon.date.toDate()) }}</td>
              <td v-if="sermon.html" colspan="4" v-html="sermon.html"></td>
              <td v-if="!sermon.html">{{ sermon.speaker }}</td>
              <td v-if="!sermon.html">
                <div v-if="sermon.mainspeakerurl" class="mb-2">
                  <a :href="sermon.mainspeakerurl" target="_blank">Main Speaker</a>
                </div>
                <div v-if="sermon.fullserviceurl" class="mb-2">
                  <a :href="sermon.fullserviceurl" target="_blank">Entire Service</a>
                </div>
                <div v-if="sermon.slidesurl">
                  <a :href="sermon.slidesurl" target="_blank">Slides</a>
                </div>
              </td>
              <td v-if="!sermon.html">{{ sermon.title }}</td>
              <td v-if="!sermon.html">{{ sermon.passage }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { collection, query, orderBy, where } from "firebase/firestore";
import { useFirestore, useCollection } from 'vuefire'
import { formatSermonDate } from "../utils/formatDate.js";

const search = ref('');
const db = useFirestore();
const col = collection(db, "audio");

const firestoreQuery = computed(() => {
  if (search.value) {
    return query(col,
      orderBy("speaker"),
      orderBy("date", "desc"),
      where("speaker", ">=", search.value),
      where("speaker", "<", search.value + '\uf8ff')
    );
  } else {
    return query(col, orderBy("date", "desc"));
  }
});

const sermonList = useCollection(firestoreQuery);
</script>

<script>
export default { name: "SermonsComponent" };
</script>

<style scoped>
.sermon-table-headings th{
  vertical-align: top;
}
</style>