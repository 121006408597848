<style scoped>
.sermon-iframe:-webkit-full-screen {
  min-width: 0 !important;
  max-width: none !important;
  width: 100% !important;
  min-height: 0 !important;
  max-height: none !important;
}
</style>

<template>
  <div class="container pt-4">
    <iframe
      id="#sermonnetframe"
      v-resize="{ log: false }"
      src="https://whiteoak.sermon.net/main"
      scrolling="no"
      frameborder="0"
      style="max-width: 100%; width: 100%; min-height: 800px; overflow: hidden"
      allowTransparency="true"
      allowfullscreen
      class="sermon-iframe"
    ></iframe>
    <div mb-4>
      Looking for older recordings? <router-link to="/sermons">Click here</router-link>. For sermons since April 2023, use the search box above.
      <p>&nbsp;</p>
    </div>
  </div>
</template>

<script setup>
import iframeResize from 'iframe-resizer/js/iframeResizer';

const vResize = {
  mounted: (el, { value = {} }) => {
    el.addEventListener('load', () => iframeResize(value, el));
  },
  unmounted: (el) => {
    el.iFrameResizer.removeListeners();
  },
};
</script>