<template>
  <!-- <PhotoSignup></PhotoSignup> -->
  <div class="container pt-4">
    <div class="row">
      <div class="col-md-6">
        <h3>Fellowship Hall & Kitchen Reservations</h3>
        <p>
          To know if the Fellowship Hall and/or Kitchen is already in use for the day/time you need, check the calendar
          page, then contact
          Bob & Erma to reserve.
        </p>
        <p>
          <router-link to="/fhcalendar" class="btn btn-secondary">Check Calendar </router-link>
        </p>
      </div>
      <div class="col-md-6">
        <h3>Child Protection Training</h3>
        <p>The last session was archived here for those who need to complete their training.</p>
        <p v-if="!trainingUrl">Loading...</p>
        <a v-if="trainingUrl" :href="trainingUrl" class="btn btn-secondary" target="_blank">View Training Video</a>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-md-6">
        <h3>Manheim Project Signup</h3>
        <p>Volunteer forms for the Manheim Project</p>
        <router-link to="/mp" class="btn btn-secondary">Sign Up</router-link>
      </div> -->
      <div class="col-md-6">
        <p>&nbsp;</p>
        <p>Using a shared computer and want to log out?</p>
        <button @click.prevent="signOut" class="btn btn-secondary">Log Out</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { ref } from "vue";
import { auth, db } from "../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { useRouter } from "vue-router";

let trainingUrl = ref(null);

const store = useStore();
const router = useRouter();

const getTrainingUrl = async function () {
  const docRef = doc(db, "member_resources", "ChildProtectionTraining");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    trainingUrl.value = docSnap.data().url;
  }
};

auth.onAuthStateChanged((user) => {
  store.dispatch("fetchUser", user);
  trainingUrl.value = null;
  if (user) {
    getTrainingUrl();
  }
});

const signOut = async () => {
  await store.dispatch("logOut");
  router.push('/')
};
</script>
<script>
export default { name: "MemberResourcesView" }
</script>