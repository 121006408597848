<template>
  <div class="container pt-4">
    <div class="row">
      <div class="col-md-12">
        <p class="">
          <span style="color: #000000"
            >1. This body of Christians originated early in the eighteenth century the church being a natural outgrowth of the Pietistic
            movement following the Reformation.</span
          >
        </p>
        <p>
          <span style="color: #000000">2. Firmly <b>accepts</b> and <b>teaches</b> the fundamental evangelical doctrines of&nbsp;</span>
        </p>
        <ul>
          <li>
            <span style="color: #000000">the&nbsp; inspiration&nbsp; of&nbsp; the&nbsp; Bible,&nbsp;</span>
          </li>
          <li>
            <span style="color: #000000">the personality of the Holy Spirit,&nbsp;</span>
          </li>
          <li><span style="color: #000000">the virgin birth,&nbsp;</span></li>
          <li>
            <span style="color: #000000">the deity of Christ,&nbsp;</span>
          </li>
          <li>
            <span style="color: #000000">the sin-pardoning value of his atonement,&nbsp;</span>
          </li>
          <li>
            <span style="color: #000000">his resurrection from the tomb, ascension and personal and visible return and&nbsp;</span>
          </li>
          <li>
            <span style="color: #000000">the resurrection, both of the just and unjust (John 5:28, 29; 1 Thess. 4:13-18).</span>&nbsp;
          </li>
        </ul>
        <p class="">
          <span style="color: #000000">3. <b>Observes</b> the&nbsp; following New Testament rites:</span>
        </p>
        <ul>
          <li>
            <span style="color: #000000"
              >Baptism of penitent believers by trine immersion&nbsp; for the&nbsp; remission of sins (Matt. 28: 19; Acts 2:
              38);&nbsp;</span
            >
          </li>
          <li>
            <span style="color: #000000">feet-washing (John 13:1-20;&nbsp; 1&nbsp; Tim.&nbsp; 5:10);&nbsp;</span>
          </li>
          <li>
            <span style="color: #000000">love feast&nbsp; (Luke&nbsp; 22:20;&nbsp; John 13: 4;&nbsp; 1 Cor. 11:17-34; Jude 12);</span>
          </li>
          <li>
            <span style="color: #000000">communion (Matt. 26: 26-30);&nbsp;</span>
          </li>
          <li>
            <span style="color: #000000">the Christian salutation (Rom. 16:16; Acts 20:37);&nbsp;</span>
          </li>
          <li>
            <span style="color: #000000">proper appearance in worship (1 Cor. 11:2-16);&nbsp;</span>
          </li>
          <li>
            <span style="color: #000000">the anointing for healing in the name of the Lord (James 5:13-18; Mark 6:13);&nbsp;</span>
          </li>
          <li>
            <span style="color: #000000">laying on of hands (Acts 8:17; 19:6; 1 Tim. 4:14).&nbsp;</span>
          </li>
        </ul>
        <p class="">
          <span style="color: #000000"
            >These&nbsp; rites&nbsp; are representative of spiritual facts which obtain in the lives of true believers, and as such are
            essential factors in the development of the Christian life.</span
          >
        </p>
        <p>
          <span style="color: #000000">4.&nbsp; <b>Emphasizes:</b></span>
        </p>
        <ul>
          <li>
            <span style="color: #000000"
              >daily devotion for the individual, and family worship for the home (Eph. 6: 18-20; Philpp. 4:8, 9);&nbsp;</span
            >
          </li>
          <li>
            <span style="color: #000000">stewardship of time, talents and money (Matt. 25:14-30);&nbsp;</span>
          </li>
          <li><span style="color: #000000">taking care of the fatherless, widows, poor, sick and aged (Acts 6:1-7).</span>&nbsp;</li>
        </ul>
        <p class="">
          <span style="color: #000000">5. <b>Opposes</b> on Scriptural grounds:&nbsp;</span>
        </p>
        <ul>
          <li>
            <span style="color: #000000">War and the taking of human life (Matt. 5:21-26, 43, 44; Rom. 12:19-21; Isa. 53:7-12);&nbsp;</span>
          </li>
          <li>
            <span style="color: #000000">violence in personal and industrial&nbsp; controversy (Matt 7: 12; Rom. 13: 8-10);&nbsp;</span>
          </li>
          <li>
            <span style="color: #000000">intemperance in all things (Titus 2: 2; Gal, 5: 19-26; Eph. 5: .18);&nbsp;</span>
          </li>
          <li>
            <span style="color: #000000">going to law, especially against our Christian brethren (1 Cor. 6:1-9);</span>
          </li>
          <li>
            <span style="color: #000000">divorce and remarriage, except&nbsp; for the one Scriptural reason (Matt 19:9);&nbsp;</span>
          </li>
          <li>
            <span style="color: #000000">every form of oath (Matt. 5: 33-37; James 5:12);&nbsp;</span>
          </li>
          <li>
            <span style="color: #000000">membership in secret oath-bound societies (2 Cor. 6:14-18);&nbsp;</span>
          </li>
          <li>
            <span style="color: #000000">games of chance and sinful amusements (1 Thess. 5:22; 1 Pet. 2:11; Rom. 12:17);&nbsp;</span>
          </li>
          <li><span style="color: #000000">extravagant and immodest dress (1 Tim. 2:8-10; 1 Peter 3:1-6).</span>&nbsp;</li>
        </ul>
        <p class="">
          <span style="color: #000000">6.&nbsp; <b>Labors</b>&nbsp; earnestly,&nbsp; in harmony with the Great Commission,&nbsp;</span>
        </p>
        <ul>
          <li>
            <span style="color: #000000">for the evangelization of the world;&nbsp;</span>
          </li>
          <li>
            <span style="color: #000000">for the conversion of men to Jesus Christ;&nbsp; and&nbsp;</span>
          </li>
          <li>
            <span style="color: #000000"
              >for the realization of the life of Jesus Christ in every believer (Matt. 28:18-20; Mark 16:15, 16; 2 Cor. 3:18).</span
            >&nbsp;
          </li>
        </ul>
        <p class="">
          <span style="color: #000000"
            >7. <b>Maintains</b> the New Testament as its only creed, in harmony with which the above brief doctrinal statement is
            made</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeliefsView",
};
</script>